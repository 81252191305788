import { useState, useRef } from "react";
import toast from "react-hot-toast";

const Contact = () => {
  const [buttonText, setButtonText] = useState("Send Message");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [showThankYou, setShowThankYou] = useState(false); // State to manage showing the thank you message
  const formRef = useRef(null);

  const contactMethods = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>
      ),
      contact: "Mandala Bhawan, Maitighar, KMC-11 Ktm Nepal",
      title: "Our office",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
          />
        </svg>
      ),
      contact: "+977 01-5911551 9828326211",
      title: "Phone",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>
      ),
      contact: "snyinternational12@gmail.com",
      title: "Email",
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setButtonText("Sending Message...");

    try {
      // Compose email body
      const mailBody = `Name: ${name}%0D%0AEmail: ${email}%0D%0APhone: ${number}%0D%0AMessage: ${message}`;

      // Open mail client with pre-filled details
      window.open(
        `mailto:snyinternational12@gmail.com?subject=Message from ${name}&body=${mailBody}`
      );

      // Reset form fields
      formRef.current.reset();
      setName("");
      setEmail("");
      setNumber("");
      setMessage("");

      // Show thank you message
      setShowThankYou(true);

      // Reset button text and submission state after 2 seconds
      setTimeout(() => {
        setButtonText("Send Message");
        setIsSubmitting(false);
        setShowThankYou(false); // Hide thank you message after showing for 2 seconds
      }, 2000);
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
      setButtonText("Send Message");
      setIsSubmitting(false);
    }
  };

  return (
    <div className="md:px-0 px-4 flex flex-col md:gap-20 gap-14 justify-center items-center">
      <div className="font-sans max-w-7xl mx-auto relative bg-gray-100 rounded-xl overflow-hidden">
        <div className="absolute -top-6 -left-6 w-20 h-20 rounded-full bg-blue-400"></div>
        <div className="absolute -bottom-6 -left-0 w-24 h-20 rounded-tr-[40px] bg-teal-200"></div>
        <div className="absolute -top-6 -right-6 w-20 h-20 rounded-full bg-blue-400"></div>
        <div className="absolute -bottom-6 -right-0 w-24 h-20 rounded-tl-[40px] bg-blue-300"></div>
        <div className="grid md:grid-cols-2">
          <div className="text-center p-6 xl:p-10 flex flex-col items-start justify-center">
            <h2 className="text-3xl text-primary-350 font-serif font-semibold">
              Contact Us SNY international
            </h2>
            <ul className="mt-12 flex flex-wrap gap-x-12 gap-y-6 items-center lg:gap-x-24">
              {contactMethods.map((item, idx) => (
                <li key={idx}>
                  <div className="mt-3 flex items-center gap-x-3">
                    <div className="flex-none text-primary-350 font-semibold duration-700">
                      {item.icon}
                    </div>
                    <p className="md:text-[19px] text-[18px] text-gray-600 cursor-pointer hover:text-gray-500 duration-200">
                      {item.contact}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <form
            onSubmit={handleSubmit}
            ref={formRef}
            className="bg-primary-250 pb-24 pt-8 px-6 xl:p-10"
          >
            <div className="max-w-sm mx-auto space-y-4">
              <input
                type="text"
                name="name"
                required
                placeholder="Name"
                className="w-full bg-gray-100 rounded py-3 px-4 text-sm outline-none"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                name="email"
                required
                placeholder="Email"
                className="w-full bg-gray-100 rounded py-3 px-4 text-sm outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="number"
                name="number"
                required
                placeholder="Phone No."
                className="w-full bg-gray-100 rounded py-3 px-4 text-sm outline-none"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
              <textarea
                placeholder="Message"
                name="message"
                required
                rows="6"
                className="w-full bg-gray-100 rounded px-4 text-sm pt-3 outline-none"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              {showThankYou && (
                <p className="text-green-600 text-center">
                  Thank you, {name}! Your message has been sent.
                </p>
              )}
              <button
                type="submit"
                className={`text-white w-full relative bg-green-600 hover:bg-green-500 duration-500 font-semibold rounded text-sm px-6 py-3 ${
                  isSubmitting ? "cursor-not-allowed" : ""
                }`}
                disabled={isSubmitting}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  fill="#fff"
                  className="mr-2 inline"
                  viewBox="0 0 548.244 548.244"
                >
                  <path
                    fillRule="evenodd"
                    d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z"
                    clipRule="evenodd"
                    data-original="#000000"
                  />
                </svg>
                {buttonText}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <iframe
          title="Description of the iframe content"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.7495041090488!2d85.31724387517069!3d27.694136026074545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191c91ffb31d%3A0x1713a70f2878645!2sSNY%20international!5e0!3m2!1sen!2snp!4v1722251508821!5m2!1sen!2snp"
          className="md:w-[1300px] md:h-[500px] h-96 w-[350px] rounded-md"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
