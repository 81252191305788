import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  const [state, setState] = useState(false);
  const [drapdownState, setDrapdownState] = useState({
    isActive: false,
    idx: null,
  });

  const location = useLocation();
  console.log(location.pathname);

  const navigation = [
    {
      title: "Home",
      path: "/",
      isDrapdown: false,
    },
    { title: "Our Services", path: "/services", isDrapdown: false },
    { title: "About Us", path: "/about", isDrapdown: false },
    { title: "Gallery", path: "/gallery", isDrapdown: false },
    { title: "Contact Us", path: "/contact", isDrapdown: false },
  ];

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest(".nav-menu"))
        setDrapdownState({ isActive: false, idx: null });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setState(false); // Close the mobile menu on navigation
  }, [location.pathname]);

  return (
    <div className="z-50 fixed left-0 right-0 top-0">
      <nav
        className={`relative z-40 bg-primary-350 w-full md:static md:text-sm md:border-none ${
          state ? "shadow-lg rounded-b-md md:rounded-none md:shadow-none" : ""
        }`}
      >
        <div className="items-center py-1 gap-x-14 px-4 max-w-screen-xl mx-auto md:flex md:px-0">
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <Link
              to="/"
              className="flex font-serif justify-center items-center gap-2"
            >
              <span className="text-[20px] text-gray-100">SNY</span>
              <span className="text-gray-100 md:text-[19px] text-[18px]">
                international
              </span>
            </Link>
            <div className="md:hidden">
              <button
                className="text-gray-100 font-semibold cursor-pointer"
                onClick={() => setState(!state)}
              >
                {state ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-gray-100 cursor-pointer font-semibold"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`nav-menu flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              state ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-end space-y-6 md:flex md:space-x-12 md:space-y-0">
              {navigation.map((item, idx) => (
                <li key={idx}>
                  {item.isDrapdown ? (
                    <button
                      className="w-full flex items-center md:justify-between gap-1 text-gray-100 italic font-serif hover:text-gray-200 md:text-[20px] text-[18px]"
                      onClick={() =>
                        setDrapdownState({
                          idx,
                          isActive: !drapdownState.isActive,
                        })
                      }
                    >
                      {item.title}
                      {drapdownState.idx === idx && drapdownState.isActive ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            fillRule="evenodd"
                            d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </button>
                  ) : (
                    <Link
                      className={`${
                        location.pathname === item.path ? "border-b" : ""
                      } block text-gray-100 font-serif hover:border-b w-max hover:text-gray-200 md:text-[20px] text-[18px]`}
                      to={item.path}
                    >
                      {item.title}
                    </Link>
                  )}
                  {item.isDrapdown &&
                  drapdownState.idx === idx &&
                  drapdownState.isActive ? (
                    <div className="md:px-0 px-4 bg-primary-500 md:overflow-hidden overflow-y-scroll md:h-max h-[60vh] mt-6 inset-x-0 top-20 w-full md:absolute md:border-y md:shadow-md md:mt-0">
                      <ul className="max-w-screen-xl mx-auto grid items-center gap-6 md:grid-cols-2 lg:grid-cols-3 md:py-10 py-6">
                        {item.navs.map((dropdownItem, idx) => (
                          <li key={idx}>
                            <p className="text-indigo-600 text-sm">
                              {dropdownItem.label}
                            </p>
                            <ul className="mt-5 space-y-6">
                              {dropdownItem.navs.map((navItem, idx) => (
                                <li key={idx} className="group">
                                  <Link
                                    to={navItem.path}
                                    className="flex gap-3 items-center"
                                  >
                                    <div className="w-12 h-12 rounded-full bg-indigo-50 text-indigo-600 flex items-center justify-center duration-150 group-hover:bg-indigo-600 group-hover:text-white md:w-14 md:h-14">
                                      {navItem.icon}
                                    </div>
                                    <div>
                                      <span className="text-gray-800 duration-200 group-hover:text-indigo-600 text-sm font-medium md:text-base">
                                        {navItem.title}
                                      </span>
                                      <p className="text-sm text-gray-100-gray-800 mt-1">
                                        {navItem.desc}
                                      </p>
                                    </div>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      {state ? (
        <div className="md:hidden z-30 fixed inset-0 w-full h-screen bg-gray-800/40 backdrop-blur-sm" />
      ) : (
        ""
      )}
    </div>
  );
}
