import React, { useState } from "react";
import ga1 from "../assets/ga1.jpg";
import ga3 from "../assets/ga3.jpg";
import ga4 from "../assets/ga4.jpg";
import i1 from "../assets/i1.jpg";
import i2 from "../assets/i2.jpg";
import i3 from "../assets/i3.jpg";
import i4 from "../assets/i4.jpg";
import i5 from "../assets/i5.jpg";
import i6 from "../assets/i6.jpg";
import i7 from "../assets/i7.jpg";
import i8 from "../assets/i8.jpg";
import i9 from "../assets/i9.jpg";
import i10 from "../assets/i10.jpg";
import i11 from "../assets/i11.jpg";
import i12 from "../assets/i12.jpg";
import gn1 from "../assets/gn1.jpg";
import gn2 from "../assets/gn2.jpg";
import gn3 from "../assets/gn3.jpg";
import gn4 from "../assets/gn4.jpg";

export default function Gallery() {
  const images = [
    { src: gn2, alt: "Louvre" },
    { src: gn3, alt: "Louvre" },
    { src: gn4, alt: "Louvre" },
    { src: ga3, alt: "Louvre" },
    { src: ga4, alt: "Louvre" },
    { src: i1, alt: "Louvre" },
    { src: i2, alt: "Louvre" },
    { src: i3, alt: "Louvre" },
    { src: i4, alt: "Louvre" },
    { src: i5, alt: "Louvre" },
    { src: i6, alt: "Louvre" },
    { src: i7, alt: "Louvre" },
    { src: i8, alt: "Louvre" },
    { src: i9, alt: "Louvre" },
    { src: i10, alt: "Louvre" },
    { src: i11, alt: "Louvre" },
    { src: i12, alt: "Louvre" },
    { src: ga1, alt: "Louvre" },

    { src: gn1, alt: "Louvre" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const showPrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? images.length - 1 : prevIndex + 1
    );
  };

  return (
    <div className="md:px-0 px-4 flex justify-center items-center">
      <div className="md:w-10/12 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10">
          {images.map((image, index) => (
            <div key={index} className="mb-4">
              <div className="relative overflow-hidden bg-cover bg-no-repeat h-72">
                <img
                  src={image.src}
                  className="w-full h-full object-cover  hover:translate-y-px  duration-200 rounded-md cursor-pointer"
                  alt={image.alt}
                  onClick={() => openModal(index)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90 p-4">
          <button
            className="absolute top-4 right-4 text-white text-4xl"
            onClick={closeModal}
          >
            &times;
          </button>
          <button
            className={`absolute left-4 text-white text-3xl ${
              currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={showPrevImage}
            disabled={currentIndex === 0}
          >
            &#9664;
          </button>
          <div className="flex justify-center items-center w-[85vw] h-96">
            <img
              src={images[currentIndex].src}
              alt={images[currentIndex].alt}
              className=" h-96 w-96 object-cover"
            />
          </div>
          <button
            className={`absolute right-4 text-white text-3xl ${
              currentIndex === images.length - 1
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={showNextImage}
            disabled={currentIndex === images.length - 1}
          >
            &#9654;
          </button>
        </div>
      )}
    </div>
  );
}
