import React from "react";
import TopSells from "../components/TopSells";
import Slider from "../components/Slider";
import Catlogue from "../components/Catlogue";
import Testimonials from "../components/Testimonials ";
import Banner from "../components/Banner";
import Review from "../components/Review";
import Smart from "../components/Smart";
import BestProduct from "../components/BestProduct";

export default function Home() {
  return (
    <div className=" flex flex-col  gap-12 md:gap-20">
      <Slider />
      <TopSells />
      <Smart />
      <Catlogue />
      <Banner />
      <BestProduct />

      <Review />
      <Testimonials />
    </div>
  );
}
