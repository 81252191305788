import React, { useState } from "react";
import c1 from "../assets/c1-removebg-preview.png";
import top5 from "../assets/top5-removebg-preview.png";
import t7 from "../assets/t7-removebg-preview.png";
import t2 from "../assets/t2-removebg-preview.png";
import rr from "../assets/rr.png";
import r from "../assets/r-removebg-preview.png";
import rrr from "../assets/rrr-removebg-preview.png";
import rrrrr from "../assets/rrrrr-removebg-preview.png";
import SectionHeadline from "./SectionHeadline";
import ntop5 from "../assets/ntop5.jpg";
import ntop6 from "../assets/ntop6.jpg";
import ntop14 from "../assets/ntop14.jpg";
import ntop16 from "../assets/ntop16.jpg";

const ProductCard = ({ product }) => {
  const { name, image, rating } = product;
  return (
    <div className=" bg-gray-50  border-primary-100 border p-3 rounded-lg group overflow-hidden cursor-pointer relative z-40 hover:before:bg-black before:absolute before:inset-0 before:opacity-10 before:transition-all w-full">
      <div className="w-full h-[300px] overflow-hidden mx-auto aspect-w-16 aspect-h-8">
        <img src={image} alt={name} className="h-full w-full object-contain" />
      </div>
      <div className="absolute mx-auto left-0 right-0 -bottom-80 group-hover:bottom-2  bg-primary-250 w-11/12 p-3 rounded-lg transition-all duration-300">
        <div className="text-center">
          <h3 className="text-base font-bold text-gray-100">{name}</h3>
        </div>
        <div className="flex justify-center space-x-1 mt-4">
          {Array.from({ length: 5 }, (_, index) => (
            <svg
              key={index}
              className={`w-4 ${
                index < rating ? "fill-[#facc15]" : "fill-[#CED5D8]"
              }`}
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
            </svg>
          ))}
        </div>
      </div>
    </div>
  );
};

const BestProduct = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? products.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === products.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="md:px-0 px-4 flex justify-center items-center">
      <div className="font-sans w-full md:w-10/12">
        <SectionHeadline title="Best Seller Catalogues" />

        {/* Mobile View */}
        <div className="relative mt-5 md:mt-8 flex items-center justify-center md:hidden">
          <button
            className="absolute left-0 transform -translate-y-1/2 text-gray-900 bg-white rounded-full p-2 shadow-lg focus:outline-none"
            onClick={handlePrevious}
          >
            &larr;
          </button>
          <div className="w-full max-w-xs px-4">
            <ProductCard product={products[currentIndex]} />
          </div>
          <button
            className="absolute right-0 transform -translate-y-1/2 text-gray-900 bg-white rounded-full p-2 shadow-lg focus:outline-none"
            onClick={handleNext}
          >
            &rarr;
          </button>
        </div>

        {/* Desktop View */}
        <div className="hidden md:grid grid-cols-1 sm:grid-cols-2 mt-5 md:mt-8 md:grid-cols-3 lg:grid-cols-4 sm:gap-6 gap-12">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestProduct;

const products = [
  {
    id: 1,
    name: "Tea Cup",
    image: c1,
    rating: 4,
  },
  {
    id: 2,
    name: "Chafing Dish Hydraulic Electric ",
    image: t7,
    rating: 4,
  },
  {
    id: 3,
    name: "Colour Coffee Cup",
    image: top5,
    rating: 4,
  },
  {
    id: 6,
    name: "Milk Pitcher",
    image: r,
    rating: 4,
  },
  {
    id: 5,
    name: "Soup Pot Black",
    image: t2,
    rating: 4,
  },
  {
    id: 4,
    name: "Chafing Dish Golden Leg Round",
    image: rr,
    rating: 4,
  },
  {
    id: 7,
    name: "Bowl",
    image: rrr,
    rating: 4,
  },
  {
    id: 8,
    name: "polycarbonate Glass",
    image: rrrrr,
    rating: 4,
  },

  {
    id: 9,
    image: ntop5,
    rating: 4,
  },
  {
    id: 10,
    image: ntop6,
    rating: 4,
  },
  {
    id: 11,
    image: ntop14,
    rating: 4,
  },
  {
    id: 12,
    image: ntop16,
    rating: 4,
  },
];
