import React from "react";

export default function Faq() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      className=" flex  overflow-y-hidden  justify-center items-center"
    >
      <div className=" md:flex md:w-10/12 md:shadow-lg rounded-md md:pb-10  justify-between w-full">
        <div className=" md:px-20  md:pt-0 pt-12  w-full">
          <div className="space-y-4">
            <p className="  flex md:justify-center items-center font-serif  text-gray-800 text-2xl md:text-3xl  mb-10 ">
              Frequently Asked Questions
            </p>
            <details className="group  rounded-t-2xl  bg-gray-100  shadow-md   hover:bg-gray-50 duration-300 p-4 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-800">
                  What products does SNY International specialize in?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5  text-primary-350 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-700">
                SNY International specializes in the provision of kitchen
                utensils, offering a wide range of products designed to meet
                various culinary needs. Their inventory includes basic tools
                like spatulas and whisks, as well as specialized equipment such
                as high-quality knives and cookware.
              </p>
            </details>
            <details className="group  rounded-t-2xl  bg-gray-100  shadow-md   hover:bg-gray-50 duration-300 p-4 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-800">
                  Where is SNY International located?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-primary-350 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-700">
                SNY International is located in the heart of Kathmandu,
                specifically at Mandala Bhawan, Maitighar, KMC-11. This central
                location makes it convenient for customers from different parts
                of the city to visit and shop for their kitchen essentials.
              </p>
            </details>
            <details className="group  rounded-t-2xl  bg-gray-100  shadow-md   hover:bg-gray-50 duration-300 p-4 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-800">
                  Why is the location of SNY International beneficial for
                  customers?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-primary-350 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-700">
                The location of SNY International is beneficial for customers
                because it is centrally located and easily accessible. Situated
                at Mandala Bhawan, Maitighar, KMC-11, it allows customers from
                various parts of Kathmandu to visit the store without much
                hassle.
              </p>
            </details>

            <details className="group  rounded-t-2xl  bg-gray-100  shadow-md   hover:bg-gray-50 duration-300 p-4 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-800">
                  What types of specialized kitchen equipment can customers find
                  at SNY International?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-primary-350 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-700">
                Customers can find specialized kitchen equipment at SNY
                International, including high-quality knives and cookware. In
                addition, the store offers a variety of basic kitchen tools such
                as spatulas and whisks.
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  );
}
