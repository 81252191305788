import React from "react";

export default function Services() {
  return (
    <div>
      <section
        style={{
          backgroundImage:
            "url('https://www.toptal.com/designers/subtlepatterns/uploads/dot-grid.png')",
        }}
        className="    flex justify-center items-center  leading-6 text-blue-900  "
      >
        <div className=" md:w-10/12 w-full md:px-0 px-4">
          <div className="relative max-w-xl">
            <h2 className="text-2xl  font-semibold leading-9   border-b-2 w-max   rounded-md border-primary-350 text-gray-800 sm:text-3xl font-serif sm:leading-tight">
              Our Services and Features
            </h2>
          </div>

          <div className=" mt-16 grid grid-cols-1 gap-14 sm:grid-cols-2 lg:grid-cols-3">
            <div className="relative hover:scale-105 duration-700 rounded-xl border-t-4 border-primary-200  cursor-pointer bg-white shadow">
              <div className="flex group flex-col items-center py-10">
                <div className="-mt-20  flex h-20 w-20 border  border-primary-250  items-center justify-center rounded-full  bg-[#F2F3EE]    text-gray-800">
                  <svg
                    className="h-8 w-8 group-hover:rotate-[30deg] transition-allion-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                    />
                  </svg>
                </div>
                <span className="mt-3  font-semibold font-serif capitalize text-gray-700">
                  Kitchen Utensils Retail
                </span>
                <p className="mt-3 text-center px-2 md:px-3 text-gray-800 ">
                  SNY offers a comprehensive range of kitchen utensils,
                  including spatulas, knives, cookware, bakeware, and other
                  essential tools, available for purchase at their retail store
                  in Kathmandu.
                </p>
              </div>
            </div>
            <div className="relative hover:scale-105 duration-700 rounded-xl border-t-4  border-primary-200  cursor-pointer bg-white shadow">
              <div className="flex group flex-col items-center py-10">
                <div className="-mt-20 flex h-20 w-20 border  border-primary-250  items-center justify-center rounded-full  bg-[#F2F3EE]   text-gray-800">
                  <svg
                    className="h-8 w-8 group-hover:rotate-[30deg] transition-all  border-primary-350 duration-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                    />
                  </svg>
                </div>
                <span className="mt-3  font-semibold font-serif capitalize text-gray-700">
                  Product Consultation
                </span>
                <p className="mt-3 text-center px-2 md:px-3 text-gray-800 ">
                  SNY provides personalized product consultation services, where
                  knowledgeable staff members assist customers in selecting the
                  right kitchen utensils based on their cooking needs,
                  preferences, and budget.
                </p>
              </div>
            </div>
            <div className="relative hover:scale-105 duration-700 rounded-xl border-t-4 border-primary-200  cursor-pointer bg-white shadow">
              <div className="flex group flex-col items-center py-10">
                <div className="-mt-20 flex h-20 w-20 border  border-primary-250  items-center justify-center rounded-full  bg-[#F2F3EE]   text-gray-800">
                  <svg
                    className="h-8 w-8 group-hover:rotate-[30deg] transition-all  border-primary-350 duration-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                    />
                  </svg>
                </div>
                <span className="mt-3  font-semibold font-serif capitalize text-gray-700">
                  Customized Gift Sets
                </span>
                <p className="mt-3 text-center px-2 md:px-3 text-gray-800 ">
                  SNY offers customized gift sets of kitchen utensils, perfect
                  for special occasions such as weddings, housewarmings, and
                  corporate events, with options for personalized packaging and
                  messaging.
                </p>
              </div>
            </div>

            <div className="relative hover:scale-105 rounded-xl border-t-4 border-primary-200    duration-700 cursor-pointer bg-white shadow">
              <div className="flex  group flex-col items-center py-10">
                <div className="-mt-20 flex h-20 w-20 border  border-primary-250  items-center justify-center rounded-full  bg-[#F2F3EE]   text-gray-800">
                  <svg
                    className="h-8 w-8 group-hover:rotate-[30deg] transition-all  border-primary-350 duration-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                    />
                  </svg>
                </div>
                <span className="mt-3  font-semibold font-serif capitalize text-gray-700">
                  Eco-Friendly Alternatives
                </span>
                <p className="mt-3 text-center px-2 md:px-3 text-gray-800 ">
                  SNY offers eco-friendly alternatives to traditional kitchen
                  utensils, including sustainable materials such as bamboo,
                  stainless steel, and silicone, supporting environmentally
                  conscious consumers in reducing their ecological footprint.
                </p>
              </div>
            </div>
            <div className="relative hover:scale-105 duration-700 rounded-xl border-t-4 border-primary-200   cursor-pointer bg-white shadow">
              <div className="flex group flex-col items-center py-10">
                <div className="-mt-20 flex h-20 w-20 border  border-primary-250  items-center justify-center rounded-full  bg-[#F2F3EE]   text-gray-800">
                  <svg
                    className="h-8 w-8 group-hover:rotate-[30deg] transition-all  border-primary-350 duration-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                    />
                  </svg>
                </div>
                <span className="mt-3  font-semibold font-serif capitalize text-gray-700">
                  Culinary Workshops
                </span>
                <p className="mt-3 text-center px-2 md:px-3 text-gray-800 ">
                  SNY hosts interactive culinary workshops and cooking classes
                  led by experienced chefs, covering a variety of cuisines,
                  techniques, and kitchen gadgets, providing customers with
                  hands-on learning experiences and culinary inspiration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
