import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Assuming you are using React Router
import sny from "../assets/sny.jpg";
const whatsappNumber = "9828326211";

export default function Review() {
  const whatsappLink = `https://wa.me/${whatsappNumber}`;
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="md:px-0 px-4 md:mt-9 flex justify-center items-center">
      <div className="relative md:w-10/12 w-full">
        <div className="mx-auto max-w-xl lg:max-w-screen-xl">
          <div className="text-center lg:mb-0 lg:max-w-lg lg:text-left">
            <div className="mb-6 max-w-4xl">
              <h2 className="mb-6 max-w-lg font-sans text-3xl font-semibold tracking-tight text-slate-800 sm:text-5xl sm:leading-snug">
                The No:1 Marketplace <br />
                in
                <span className="inline-block px-4 text-primary-350 font-serif">
                  kathmandu Nepal
                </span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                The No:1 Marketplace in Kathmandu offers a wide variety of home
                kitchen supplies, attracting locals and tourists with its
                extensive selection and quality products.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-5 lg:justify-start">
              <Link
                to="/about"
                className="inline-flex h-12 items-center justify-center rounded-full bg-primary-350 md:px-8 px-16 font-medium tracking-wide text-white shadow-md outline-none transition duration-200 hover:bg-primary-300 focus:ring sm:w-auto"
              >
                More About Us
              </Link>
              <Link
                to={whatsappLink}
                target="_blank"
                rel="noreferrer noopener"
                className="inline-flex h-12 items-center justify-center rounded-full border-2 border-primary-350 md:px-8 px-14 font-semibold text-gray-700 transition-colors duration-200 hover:border-y-primary-300 hover:text-gray-500 sm:w-auto"
              >
                Direct Message
              </Link>
            </div>

            <div className="mt-8 flex justify-center -space-x-4 lg:justify-start">
              {/* Your customer review section */}
            </div>
          </div>
          <div className="md:h-[72vh] h-full justify-center overflow-hidden lg:absolute lg:-bottom-24 lg:right-0 lg:flex lg:w-1/1 lg:items-end lg:justify-start">
            <svg
              className="h-full w-full"
              viewBox="0 0 200 200"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <pattern id="img1" x="0" y="0" width="1" height="1">
                  <image
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    className="object-cover cursor-pointer"
                    preserveAspectRatio="xMaxYMax slice"
                    href={sny}
                  />
                </pattern>
              </defs>

              <path
                fill="url(#img1)"
                d="M40,-62.6C52.2,-54.5,62.5,-43.9,66.9,-31.4C71.3,-18.9,69.6,-4.6,65.9,8.3C62.2,21.1,56.4,32.5,49.2,45.2C42.1,57.9,33.7,72.1,22.2,75.3C10.7,78.5,-3.9,70.7,-14.8,62.1C-25.7,53.5,-32.8,44.1,-44.9,35.8C-57,27.5,-74,20.3,-82.1,7.7C-90.3,-4.8,-89.5,-22.7,-80.8,-34.8C-72,-46.9,-55.2,-53.3,-40.4,-60.2C-25.6,-67,-12.8,-74.3,0.6,-75.2C13.9,-76.1,27.9,-70.6,40,-62.6Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
