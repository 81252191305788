import React, { useState, useRef } from "react";
import SectionHeadline from "./SectionHeadline";
import top1 from "../assets/top1.jpg";
import top2 from "../assets/el.jpg";
import top3 from "../assets/top3.jpg";
import top4 from "../assets/snyn2.jpg";
import top5 from "../assets/top5.jpg";
import top6 from "../assets/dis.jpg";
import top7 from "../assets/top7.jpg";
import top8 from "../assets/c.jpg";
import n4 from "../assets/n1.jpg";
import n5 from "../assets/n5.jpg";
import n6 from "../assets/n6.jpg";
import n7 from "../assets/n3.jpg";
import ntop1 from "../assets/ntop1.jpg";
import ntop3 from "../assets/ntop3.jpg";
import ntop4 from "../assets/ntop4.jpg";
import ntop7 from "../assets/ntop7.jpg";
import ntop8 from "../assets/ntop8.jpg";
import ntop9 from "../assets/ntop9.jpg";
import ntop11 from "../assets/ntop11.jpg";
import ntop12 from "../assets/ntop12.jpg";
import ntop15 from "../assets/ntop15.jpg";
import d1 from "../assets/d11.jpg";
import d2 from "../assets/d2.jpg";
import d3 from "../assets/d3.jpg";
import d4 from "../assets/d4.jpg";
import d5 from "../assets/d5.jpg";
import d6 from "../assets/d6.jpg";
import d7 from "../assets/d7.jpg";
import d8 from "../assets/d8.jpg";
import d9 from "../assets/d9.jpg";
import d10 from "../assets/d10.jpg";
import d12 from "../assets/d12.jpg";

export default function TopSells() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [processingOrder, setProcessingOrder] = useState(false);
  const formRef = useRef(null);

  const handleOrderNowClick = (product) => {
    setSelectedProduct(product);
    openOrderForm();
  };

  const handleCloseForm = () => {
    setName("");
    setEmail("");
    setAddress("");
    setSelectedProduct(null); // Reset selectedProduct to close the form
  };

  const handleSubmitOrder = async (event) => {
    event.preventDefault();
    if (!selectedProduct) return;

    setProcessingOrder(true);

    try {
      const mailBody = `Name: ${name}\nEmail: ${email}\nAddress: ${address}`;

      window.open(
        `mailto:snyinternational12@gmail.com?subject=Order for ${encodeURIComponent(
          selectedProduct.name
        )}&body=${encodeURIComponent(mailBody)}`
      );

      handleCloseForm();
    } catch (error) {
      console.error("Failed to send order. Please try again later.", error);
      setProcessingOrder(false);
    }
  };

  const openOrderForm = () => {
    // Implement logic to display order form (e.g., toggle a state)
  };

  return (
    <div>
      <section className="md:px-0 px-4 flex justify-center items-center bg-white text-gray-700">
        <div className="w-full md:w-10/12">
          <div className="text-center md:max-w-[900px] md:mx-auto">
            <SectionHeadline title="Our Top Catalogues" />
            <p className="md:text-xl text-[17px] italic font-serif text-primary mt-2">
              Discover our bestsellers, chosen for their quality and popularity.
              From innovative electronics to trendy fashion, these top picks are
              customer favorites. Explore now to see why these products are
              leading the market.
            </p>
          </div>

          <div className="md:mt-10 mt-7 grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-8 lg:mt-10">
            {products.map((product) => (
              <article
                key={product.id}
                className="relative group flex flex-col overflow-hidden rounded-lg border"
              >
                <div className="aspect-square overflow-hidden">
                  <img
                    className="h-full w-full object-cover transition-all duration-500 group-hover:scale-105"
                    src={product.imgSrc}
                    alt={product.name}
                  />
                </div>
                {product.sale && (
                  <div className="absolute top-0 m-2 rounded-full bg-white">
                    <p className="rounded-full bg-emerald-500 p-1 text-[8px] font-bold uppercase tracking-wide text-white sm:py-1 sm:px-3">
                      Sale
                    </p>
                  </div>
                )}
                <div className="my-4 mx-auto flex w-10/12 flex-col items-start justify-between">
                  <h3 className="mb-2 text-sm text-gray-600">{product.name}</h3>
                </div>
                <button
                  onClick={() => handleOrderNowClick(product)}
                  className="group mx-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600"
                >
                  <div className="flex w-full items-center justify-center font-semibold bg-gray-100 duration-500 text-xs transition group-hover:bg-primary-300 group-hover:text-white">
                    Order Now
                  </div>
                  <div className="flex items-center justify-center bg-gray-200 px-5 transition duration-500 group-hover:bg-primary-350 group-hover:text-white">
                    +
                  </div>
                </button>
              </article>
            ))}
          </div>
        </div>
      </section>

      {selectedProduct && (
        <div className="md:px-0 px-4 fixed inset-0 z-50 flex items-center justify-center bg-black/80">
          <div className="bg-white p-6 rounded-md w-full md:w-1/2 lg:w-1/4">
            <h3 className="text-xl font-semibold font-serif mb-4">
              Order {selectedProduct.name}
            </h3>
            <form ref={formRef} onSubmit={handleSubmitOrder}>
              <div className="mb-4">
                <label className="block text-sm mb-1" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full border outline-primary-150 border-gray-300 p-2 rounded"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-1" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full border outline-primary-150 border-gray-300 p-2 rounded"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-1" htmlFor="address">
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  className="w-full border outline-primary-150 border-gray-300 p-2 rounded"
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="flex gap-3 mb-4">
                <label className="block text-sm font-medium text-gray-800">
                  Your Order Name:
                </label>
                <p className="text-sm">{selectedProduct.name}</p>
              </div>
              <div className="flex gap-4 justify-end">
                <button
                  type="button"
                  onClick={handleCloseForm}
                  className="mr-2 px-4 py-1 bg-gray-200 hover:bg-gray-300 duration-300 text-gray-800 rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-1 bg-primary-350 hover:bg-primary-300 duration-300 text-white rounded-md"
                >
                  {processingOrder ? "Processing..." : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

const products = [
  {
    id: 1,
    imgSrc: top1,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Milk Pitcher",
  },
  {
    id: 2,
    imgSrc: top2,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Chafing Dish Hydraulic Electric Eolden",
  },
  {
    id: 3,
    imgSrc: top3,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Colour Tea Cup",
  },
  {
    id: 4,
    imgSrc: top4,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Tea Cup",
  },
  {
    id: 5,
    imgSrc: top5,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Colour Coffee Cup",
  },
  {
    id: 6,
    imgSrc: top6,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Chafing Dish Golden Leg Round",
  },
  {
    id: 7,
    imgSrc: top7,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Bowl",
  },
  {
    id: 8,
    imgSrc: top8,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Chafing Dish Squire Golden Log",
  },
  {
    id: 9,
    imgSrc: n4,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Copper Platter/Over Rect",
  },
  {
    id: 10,
    imgSrc: n5,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Acrylic  Glass",
  },
  {
    id: 11,
    imgSrc: n6,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Acrylic  Glass",
  },
  {
    id: 12,
    imgSrc: n7,
    sale: false,
    price: "$99.00",
    oldPrice: "$79.00",
    name: "Spoon Cutlery Basket",
  },

  {
    imgSrc: d1,
  },
  {
    imgSrc: d2,
  },
  {
    imgSrc: d3,
  },
  {
    imgSrc: d4,
  },
  {
    imgSrc: d5,
  },
  {
    imgSrc: d6,
  },
  {
    imgSrc: d7,
  },
  {
    imgSrc: d8,
  },
  {
    imgSrc: d9,
  },
  {
    imgSrc: d10,
  },
  {
    imgSrc: d12,
  },
  {
    imgSrc: ntop1,
  },

  {
    imgSrc: ntop3,
  },
  {
    imgSrc: ntop4,
  },

  {
    imgSrc: ntop15,
  },
  {
    imgSrc: ntop7,
  },
  {
    imgSrc: ntop8,
  },
  {
    imgSrc: ntop9,
  },

  {
    imgSrc: ntop11,
  },
  {
    imgSrc: ntop12,
  },
];
