import React from "react";

const SectionHeadline = ({ title, icon }) => {
  return (
    <div className=" flex justify-center items-center gap-2">
      {icon ? icon : null}
      <h1 className="text-gray-800    text-2xl font-serif sm:text-3xl">
        {title}
      </h1>
    </div>
  );
};

export default SectionHeadline;
