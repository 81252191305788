import React, { useState } from "react";
import SectionHeadline from "./SectionHeadline";

export default function Catalogue() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (scrollOffset) => {
    setScrollPosition((prevPosition) => prevPosition + scrollOffset);
  };

  return (
    <section className="md:px-0 px-4 flex justify-center">
      <div className="md:w-10/12 w-full">
        <div className="text-center md:max-w-[900px] md:mx-auto">
          <SectionHeadline title="Our Catalogues" />
          <p className="md:text-xl text-[17px] italic font-serif text-primary mt-2">
            Explore our curated catalogues for the latest trends and timeless
            classics. Find everything from tech to fashion with ease. Start
            browsing today!
          </p>
        </div>
        <div className="relative mt-8 bg-white shadow-2xl shadow-gray-400/80 rounded-lg">
          <div
            className="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-8 gap-4 p-4 md:p-6 overflow-x-auto"
            style={{ height: "300px" }}
          >
            {images
              .slice(scrollPosition, scrollPosition + 16)
              .map((imageUrl, index) => (
                <div
                  key={index}
                  className="group flex flex-col items-center justify-center gap-3 p-2 duration-200"
                  style={{ height: "72px", weight: "72px" }} // Adjust this height as needed
                >
                  <img
                    src={imageUrl}
                    alt={`img ${index}`}
                    height={72}
                    width={72}
                    className="object-cover duration-200 group-hover:-translate-y-px"
                  />
                </div>
              ))}
          </div>
          {scrollPosition > 0 && (
            <button
              className="absolute left-2 top-1/2  text-xl transform -translate-y-1/2  hover:bg-primary-300 duration-200 bg-primary-350  text-white rounded-md shadow-md px-3 py-1"
              onClick={() => handleScroll(-16)}
            >
              {"<"}
            </button>
          )}
          {scrollPosition + 16 < images.length && (
            <button
              className="absolute right-2 text-xl top-1/2 transform -translate-y-1/2 hover:bg-primary-300 duration-200 bg-primary-350  text-white rounded-md  shadow-md px-3 py-1"
              onClick={() => handleScroll(16)}
            >
              {">"}
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

// Assume images is imported as an array of image paths
const images = [
  require("../assets/top7.jpg"),
  require("../assets/top8.jpg"),
  require("../assets/top9.jpg"),
  require("../assets/top10.jpg"),
  require("../assets/top11.jpg"),
  require("../assets/top12.jpg"),
  require("../assets/g1.jpg"),
  require("../assets/g2.jpg"),
  require("../assets/g3.jpg"),
  require("../assets/g4.jpg"),
  require("../assets/g5.jpg"),
  require("../assets/g6.jpg"),
  require("../assets/bo1.jpg"),
  require("../assets/bo2.jpg"),
  require("../assets/bo3.jpg"),
  require("../assets/bo4.jpg"),
  require("../assets/bo5.jpg"),
  require("../assets/bo6.jpg"),
  require("../assets/ko1.jpg"),
  require("../assets/ko2.jpg"),
  require("../assets/ko3.jpg"),
  require("../assets/ko4.jpg"),
  require("../assets/ko5.jpg"),
  require("../assets/ko6.jpg"),
  require("../assets/t1.jpg"),
  require("../assets/t2.jpg"),
  require("../assets/t3.jpg"),
  require("../assets/t4.jpg"),
  require("../assets/t5.jpg"),
  require("../assets/t6.jpg"),
  require("../assets/t7.jpg"),
  require("../assets/t8.jpg"),
  require("../assets/n1.jpg"),
  require("../assets/n2.jpg"),
  require("../assets/n3.jpg"),
  require("../assets/n4.jpg"),
  require("../assets/n5.jpg"),
  require("../assets/n6.jpg"),
  require("../assets/n7.jpg"),
  require("../assets/c1.jpg"),
  require("../assets/c2.jpg"),
  require("../assets/c3.jpg"),
  require("../assets/c4.jpg"),
  require("../assets/c5.jpg"),
  require("../assets/c6.jpg"),
  require("../assets/c7.jpg"),
  require("../assets/c8.jpg"),
  require("../assets/c9.jpg"),
  require("../assets/c10.jpg"),
  require("../assets/c11.jpg"),
  require("../assets/c12.jpg"),
  require("../assets/c13.jpg"),
  require("../assets/c14.jpg"),
  require("../assets/c15.jpg"),
  require("../assets/c16.jpg"),
  require("../assets/c17.jpg"),
  require("../assets/c18.jpg"),
  require("../assets/c19.jpg"),
  require("../assets/c20.jpg"),
  require("../assets/c21.jpg"),
  require("../assets/c22.jpg"),
  require("../assets/c23.jpg"),
  require("../assets/c24.jpg"),
  require("../assets/c25.jpg"),
  require("../assets/c26.jpg"),
  require("../assets/c27.jpg"),
  require("../assets/c28.jpg"),
  require("../assets/c29.jpg"),
  require("../assets/c30.jpg"),
  require("../assets/c31.jpg"),
  require("../assets/c32.jpg"),
  require("../assets/c34.jpg"),
  require("../assets/c35.jpg"),
  require("../assets/c36.jpg"),
  require("../assets/c37.jpg"),
  require("../assets/c38.jpg"),
  require("../assets/c39.jpg"),
  require("../assets/c40.jpg"),
];

// import React from "react";
// import SectionHeadline from "./SectionHeadline";
// import top7 from "../assets/top7.jpg";
// import top8 from "../assets/top8.jpg";
// import top9 from "../assets/top9.jpg";
// import top10 from "../assets/top10.jpg";
// import top11 from "../assets/top11.jpg";
// import top12 from "../assets/top12.jpg";
// import g1 from "../assets/g1.jpg";
// import g2 from "../assets/g2.jpg";
// import g3 from "../assets/g3.jpg";
// import g4 from "../assets/g4.jpg";
// import g5 from "../assets/g5.jpg";
// import g6 from "../assets/g6.jpg";
// import bo1 from "../assets/bo1.jpg";
// import bo2 from "../assets/bo2.jpg";
// import bo3 from "../assets/bo3.jpg";
// import bo4 from "../assets/bo4.jpg";
// import bo5 from "../assets/bo5.jpg";
// import bo6 from "../assets/bo6.jpg";
// import ko1 from "../assets/ko1.jpg";
// import ko2 from "../assets/ko2.jpg";
// import ko3 from "../assets/ko3.jpg";
// import ko4 from "../assets/ko4.jpg";
// import ko5 from "../assets/ko5.jpg";
// import ko6 from "../assets/ko6.jpg";
// import t1 from "../assets/t1.jpg";
// import t2 from "../assets/t2.jpg";
// import t3 from "../assets/t3.jpg";
// import t4 from "../assets/t4.jpg";
// import t5 from "../assets/t5.jpg";
// import t6 from "../assets/t6.jpg";
// import t7 from "../assets/t7.jpg";
// import t8 from "../assets/t8.jpg";
// import n1 from "../assets/n1.jpg";
// import n2 from "../assets/n2.jpg";
// import n3 from "../assets/n3.jpg";
// import n4 from "../assets/n4.jpg";
// import n5 from "../assets/n5.jpg";
// import n6 from "../assets/n6.jpg";
// import n7 from "../assets/n7.jpg";

// export default function Catlogue() {
//   return (
//     <section className=" md:px-0 px-4  flex justify-center">
//       <div className=" md:w-10/12 w-full">
//         <div className="text-center md:max-w-[900px] md:mx-auto">
//           <SectionHeadline title="  Our Catlogues " />
//           <p className="md:text-xl text-[17px] italic font-serif  text-primary mt-2">
//             Explore our curated catalogues for the latest trends and timeless
//             classics. Find everything from tech to fashion with ease. Start
//             browsing today!
//           </p>
//         </div>
//         <div className="grid grid-cols-3 rounded-lg mt-8 bg-white shadow-2xl shadow-gray-400/80 sm:grid-cols-5 md:grid-cols-8">
//           {data.map(({ title, imageUrl }) => (
//             <div
//               key={title}
//               className="group flex cursor-pointer flex-col items-center justify-center gap-3 p-4 duration-200"
//             >
//               <img
//                 src={imageUrl}
//                 alt={title}
//                 height={72}
//                 width={72}
//                 className="object-cover duration-200 group-hover:-translate-y-px"
//               />
//               {/* <p className="text-center text-sm font-semibold">{title}</p> */}
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// }

// const data = [
//   {
//     title: "Disposable Diapers",
//     imageUrl: top7,
//   },

//   {
//     title: "Smartwatches",
//     imageUrl: top8,
//   },

//   {
//     title: "Bath & Body Accessories",
//     imageUrl: top9,
//   },

//   {
//     title: "Decorative Door Stops",
//     imageUrl: top10,
//   },

//   {
//     title: "Base Layers",
//     imageUrl: top11,
//   },

//   {
//     title: "Serum & Essence",
//     imageUrl: top12,
//   },

//   {
//     title: "Bras",
//     imageUrl: g1,
//   },

//   {
//     title: "Art Pads, Diaries 7 Folios",
//     imageUrl: g2,
//   },

//   {
//     title: "Graters, Peelers & Slicers",
//     imageUrl: g3,
//   },

//   {
//     title: "Pots, Planters & Urns",
//     imageUrl: g4,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: g5,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: g6,
//   },
//   {
//     title: "Bras",
//     imageUrl: bo1,
//   },

//   {
//     title: "Art Pads, Diaries 7 Folios",
//     imageUrl: bo2,
//   },

//   {
//     title: "Graters, Peelers & Slicers",
//     imageUrl: bo3,
//   },

//   {
//     title: "Pots, Planters & Urns",
//     imageUrl: bo4,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: bo5,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: bo6,
//   },
//   {
//     title: "Bras",
//     imageUrl: ko1,
//   },

//   {
//     title: "Art Pads, Diaries 7 Folios",
//     imageUrl: ko2,
//   },

//   {
//     title: "Graters, Peelers & Slicers",
//     imageUrl: ko3,
//   },

//   {
//     title: "Pots, Planters & Urns",
//     imageUrl: ko4,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: ko5,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: ko6,
//   },
//   {
//     title: "Bras",
//     imageUrl: t1,
//   },

//   {
//     title: "Art Pads, Diaries 7 Folios",
//     imageUrl: t2,
//   },

//   {
//     title: "Graters, Peelers & Slicers",
//     imageUrl: t3,
//   },

//   {
//     title: "Pots, Planters & Urns",
//     imageUrl: t4,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: t5,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: t6,
//   },
//   {
//     title: "Laptop Backpack",
//     imageUrl: t7,
//   },

//   {
//     title: "Laptop Backpack",
//     imageUrl: t8,
//   },
//   {
//     imageUrl: n1,
//   },
//   {
//     imageUrl: n2,
//   },
//   {
//     imageUrl: n3,
//   },
//   {
//     imageUrl: n4,
//   },
//   {
//     imageUrl: n5,
//   },
//   {
//     imageUrl: n6,
//   },
//   {
//     imageUrl: n7,
//   },
// ];
