import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

export default function Smart() {
  return (
    <div className="md:px-0 px-4 flex justify-center items-center pt-12">
      <div className="font-sans text-[#333] w-full md:w-10/12">
        <div className="grid md:grid-cols-2 md:gap-8 gap-6">
          <div className="max-md:text-center z-40 relative">
            <h2 className="lg:text-5xl md:text-4xl text-3xl font-bold mb-4">
              <span className="text-primary-350">Smart</span> Business{" "}
              <span className="text-primary-350">with</span> Smart People
            </h2>
            <p className="mt-6 text-base leading-relaxed">
              Smart Business with Smart People in kitchen utensils combines
              innovative design and high-quality materials to create efficient,
              user-friendly tools. This approach ensures superior cooking
              experiences and long-lasting products.
            </p>
            <Link to="/about">
              <button
                type="button"
                className="bg-primary-350 duration-500 hover:bg-transparent hover:text-primary-350 border-2 border-blue-600 mt-10 transition-all text-white font-bold text-sm rounded-md px-16 py-2.5"
              >
                Get Started
              </button>
            </Link>
          </div>
          <div className="md:pl-14">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-16 items-center">
              <div className="flex flex-col items-center text-center">
                <h5 className="font-bold text-3xl font-serif mb-1">
                  <CountUp end={10} duration={40} />+
                </h5>
                <p>Years Experience</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <h5 className="font-bold text-3xl font-serif mb-1">
                  <CountUp end={200} duration={40} />+
                </h5>
                <p>Glasswear</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <h5 className="font-bold text-3xl font-serif mb-1">
                  <CountUp end={1000} duration={40} />+
                </h5>
                <p>Catalogues</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <h5 className="font-bold text-3xl font-serif mb-1">
                  <CountUp end={100} duration={40} />%
                </h5>
                <p>Happy Customer</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <h5 className="font-bold text-3xl font-serif mb-1">
                  <CountUp end={250} duration={40} />+
                </h5>
                <p>Kitchen Utensils</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <h5 className="font-bold text-3xl font-serif mb-1">
                  <CountUp end={890} duration={40} />
                </h5>
                <p>Cases Solved</p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-3 gap-4 z-40 relative md:px-4 mt-10">
          <div className="bg-white p-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-12 mb-4 inline-block bg-blue-100 p-3 rounded-md"
              viewBox="0 0 32 32"
            >
              <path d="M28.068 12h-.128a.934.934 0 0 1-.864-.6.924.924 0 0 1 .2-1.01l.091-.091a2.938 2.938 0 0 0 0-4.147l-1.511-1.51a2.935 2.935 0 0 0-4.146 0l-.091.091A.956.956 0 0 1 20 4.061v-.129A2.935 2.935 0 0 0 17.068 1h-2.136A2.935 2.935 0 0 0 12 3.932v.129a.956.956 0 0 1-1.614.668l-.086-.091a2.935 2.935 0 0 0-4.146 0l-1.516 1.51a2.938 2.938 0 0 0 0 4.147l.091.091a.935.935 0 0 1 .185 1.035.924.924 0 0 1-.854.579h-.128A2.935 2.935 0 0 0 1 14.932v2.136A2.935 2.935 0 0 0 3.932 20h.128a.934.934 0 0 1 .864.6.924.924 0 0 1-.2 1.01l-.091.091a2.938 2.938 0 0 0 0 4.147l1.51 1.509a2.934 2.934 0 0 0 4.147 0l.091-.091a.936.936 0 0 1 1.035-.185.922.922 0 0 1 .579.853v.129A2.935 2.935 0 0 0 14.932 31h2.136A2.935 2.935 0 0 0 20 28.068v-.129a.956.956 0 0 1 1.614-.668l.091.091a2.935 2.935 0 0 0 4.146 0l1.511-1.509a2.938 2.938 0 0 0 0-4.147l-.091-.091a.935.935 0 0 1-.185-1.035.924.924 0 0 1 .854-.58h.128A2.935 2.935 0 0 0 31 17.068v-2.136A2.935 2.935 0 0 0 28.068 12ZM29 17.068a.933.933 0 0 1-.932.932h-.128a2.956 2.956 0 0 0-2.083 5.028l.09.091a.934.934 0 0 1 0 1.319l-1.511 1.509a.932.932 0 0 1-1.318 0l-.09-.091A2.957 2.957 0 0 0 18 27.939v.129a.933.933 0 0 1-.932.932h-2.136a.933.933 0 0 1-.932-.932v-.129a2.951 2.951 0 0 0-5.028-2.082l-.091.091a.934.934 0 0 1-1.318 0l-1.51-1.509a.934.934 0 0 1 0-1.319l.091-.091A2.956 2.956 0 0 0 4.06 18h-.128A.933.933 0 0 1 3 17.068v-2.136A.933.933 0 0 1 3.932 14h.128a2.956 2.956 0 0 0 2.083-5.028l-.09-.091a.933.933 0 0 1 0-1.318l1.51-1.511a.932.932 0 0 1 1.318 0l.09.091A2.957 2.957 0 0 0 14 4.061v-.129A.933.933 0 0 1 14.932 3h2.136a.933.933 0 0 1 .932.932v.129a2.956 2.956 0 0 0 5.028 2.082l.091-.091a.932.932 0 0 1 1.318 0l1.51 1.511a.933.933 0 0 1 0 1.318l-.091.091A2.956 2.956 0 0 0 27.939 14h.128a.933.933 0 0 1 .932.932Z"></path>
              <path d="M16 7.932A8.068 8.068 0 1 0 24.068 16 8.077 8.077 0 0 0 16 7.932Zm0 14.203a6.135 6.135 0 1 1 6.135-6.135A6.142 6.142 0 0 1 16 22.135Z"></path>
              <path d="M20.396 13.729a4.583 4.583 0 1 1-4.583-4.583 4.588 4.588 0 0 1 4.583 4.583Zm-4.583-2.797a2.797 2.797 0 1 0 2.797 2.797 2.8 2.8 0 0 0-2.797-2.797Z"></path>
            </svg>
            <h5 className="text-lg font-bold mb-2"> Customized Gift Sets</h5>
            <p className="text-sm leading-relaxed">
              SNY offers customized gift sets of kitchen utensils, perfect for
              special occasions such as weddings, housewarmings, and corporate
              events, with options for personalized packaging and messaging.
            </p>
          </div>
          <div className="bg-white p-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-12 mb-4 inline-block bg-blue-100 p-3 rounded-md"
              viewBox="0 0 32 32"
            >
              <path d="M6.063 13.27a.938.938 0 1 1-1.327 0 8.022 8.022 0 0 1 0-11.352.938.938 0 1 1 1.327 1.327 6.146 6.146 0 0 0 0 8.698Zm0 0M26.557 12.333a6.148 6.148 0 0 0-4.374-10.52.938.938 0 1 0 .672 1.752 4.274 4.274 0 0 1 3.048 7.313.938.938 0 1 0 1.326 1.326Zm0 0M8.145 23.774a.934.934 0 0 0-1.11.714 9.353 9.353 0 0 0 9.08 11.645 9.36 9.36 0 0 0 9.086-9.086 9.353 9.353 0 0 0-11.646-9.08.937.937 0 0 0 .397 1.807 7.48 7.48 0 0 1 5.615 7.272 7.488 7.488 0 0 1-7.272 7.273 7.481 7.481 0 0 1-7.272-5.615.937.937 0 0 0-.714-1.11Zm0 0M15.747 7.574a.938.938 0 0 0 0 1.327 6.147 6.147 0 0 1 0 8.698.938.938 0 1 0 1.327 1.327 8.022 8.022 0 0 0 0-11.352Zm0 0"></path>
            </svg>
            <h5 className="text-lg font-bold mb-2">
              {" "}
              Eco-Friendly Alternatives
            </h5>

            <p className="text-sm leading-relaxed">
              SNY offers eco-friendly alternatives to traditional kitchen
              utensils, including sustainable materials such as bamboo,
              stainless steel, and silicone, supporting environmentally.
            </p>
          </div>
          <div className="bg-white p-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-12 mb-4 inline-block bg-blue-100 p-3 rounded-md"
              viewBox="0 0 32 32"
            >
              <path d="M6.063 13.27a.938.938 0 1 1-1.327 0 8.022 8.022 0 0 1 0-11.352.938.938 0 1 1 1.327 1.327 6.146 6.146 0 0 0 0 8.698Zm0 0M26.557 12.333a6.148 6.148 0 0 0-4.374-10.52.938.938 0 1 0 .672 1.752 4.274 4.274 0 0 1 3.048 7.313.938.938 0 1 0 1.326 1.326Zm0 0M8.145 23.774a.934.934 0 0 0-1.11.714 9.353 9.353 0 0 0 9.08 11.645 9.36 9.36 0 0 0 9.086-9.086 9.353 9.353 0 0 0-11.646-9.08.937.937 0 0 0 .397 1.807 7.48 7.48 0 0 1 5.615 7.272 7.488 7.488 0 0 1-7.272 7.273 7.481 7.481 0 0 1-7.272-5.615.937.937 0 0 0-.714-1.11Zm0 0M15.747 7.574a.938.938 0 0 0 0 1.327 6.147 6.147 0 0 1 0 8.698.938.938 0 1 0 1.327 1.327 8.022 8.022 0 0 0 0-11.352Zm0 0"></path>
            </svg>
            <h5 className="text-lg font-bold mb-2"> Product Consultation</h5>
            <p className="text-sm leading-relaxed">
              SNY provides personalized product consultation services, where
              knowledgeable staff members assist customers in selecting the
              right kitchen utensils based on their cooking needs, preferences,
              and budget.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
