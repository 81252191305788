import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionHeadline from "./SectionHeadline";
import n1 from "../assets/sny.jpg";

const TestimonialData = [
  {
    id: 1,
    name: "Rajan Bahadur Shahi",
    text: "SNY kitchen utensils are top quality and very durable. The eco-friendly options are fantastic, and the staff is incredibly helpful. Highly recommended It is Best Sny International!",
    img: n1,
  },

  {
    id: 3,
    name: "Loki Chaulagain",
    text: "The culinary workshops at SNY have improved my cooking skills immensely. Their utensils are excellent, and the product consultation helped me find the perfect tools.",
    img: n1,
  },
  {
    id: 4,
    name: "Subeena Gurung",
    text: "SNY customized gift sets are a hit with my clients, and their repair service keeps my utensils in great shape. Their attention to detail and customer service are outstanding!",
    img: n1,
  },
];

const Testimonials = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex md:-mt-0  -mt-16 justify-center items-center">
      <div className="md:w-10/12 w-full">
        {/* header section */}
        <div className="text-center max-w-[600px] mx-auto">
          <SectionHeadline title=" Testimonial " />
          <p className="text-xl italic font-serif  text-primary">
            What our customers are saying
          </p>
        </div>

        {/* Testimonial cards */}
        <div>
          <Slider {...settings}>
            {TestimonialData.map((data) => (
              <div key={data.id} className=" md:mt-7 mt-6">
                <div className="flex flex-col gap-4 shadow-lg py-8 px-6 mx-6 rounded-xl bg-primary-50  relative">
                  <div className="mb-4">
                    <img
                      src={data.img}
                      alt={data.name}
                      className="rounded-full w-20 h-20"
                    />
                  </div>
                  <div className="flex flex-col items-center gap-4">
                    <div className="space-y-3 text-center">
                      <p className="text-md text-gray-900 font-medium">
                        {data.text}
                      </p>
                      <h1 className="text-xl font-bold  font-serif text-black/90  italic dark:text-light">
                        {data.name}
                      </h1>
                    </div>
                  </div>
                  <p className="   text-primary-300 text-8xl font-serif absolute top-0 right-0">
                    ,,
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
