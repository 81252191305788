import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Services from "./pages/Services";
import Email from "./components/Email";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import { Toaster } from "react-hot-toast";
import WhatsApp from "./components/WhatsApp";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Navbar />
        <div className="  md:py-28 py-24 ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>{" "}
        </div>
        <Email />
        <WhatsApp />
        <Footer />
        <Toaster />
      </BrowserRouter>
    </div>
  );
}

export default App;
