import React from "react";
import { HiOutlineMail } from "react-icons/hi";

export default function Email() {
  return (
    <div className="  fixed right-3   md:bottom-14  bottom-12 z-50 flex   justify-start items-center">
      <a
        href="mailto:snyinternational12@gmail.com "
        target="_blank"
        rel="noreferrer"
        class="button"
      >
        <div class="icon">
          <HiOutlineMail />
        </div>
        <p>Get in touch</p>
      </a>
    </div>
  );
}
