import React from "react";
import sny1 from "../assets/sny1.jpg";
import sny2 from "../assets/sny2.jpg";
import Faq from "./Faq";

export default function About() {
  return (
    <section className="w-full flex flex-col md:gap-10 md:px-0 px-4 mx-auto bg-white text-black">
      <div className="w-fit pb-1 px-2 md:mx-36 rounded-md text-2xl font-semibold border-b-2 border-primary-350 font-serif">
        About Sny International
      </div>

      <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex flex-col md:flex-row gap-8 justify-center items-stretch mt-10">
        <div className="w-full md:w-[50%]">
          <img
            className="rounded-md md:h-80 h-52 object-cover"
            src={sny1}
            alt="Service"
          />
        </div>
        <div className="w-full md:w-[50%] bg-gray-100 text-gray-800 p-4 rounded-md">
          <h2 className="text-2xl font-serif font-semibold">
            What does SNY do, and where is it located?
          </h2>
          <p className="text-[17px] mt-4">
            SNY specializes in the provision of kitchen utensils, offering a
            wide range of products designed to meet various culinary needs.
            Their inventory includes everything from basic tools like spatulas
            and whisks to more specialized equipment such as high-quality knives
            and cookware. This business is situated in the heart of Kathmandu,
            specifically at Mandala Bhawan, Maitighar, KMC-11. The location is
            central and accessible, making it convenient for customers from
            different parts of the city to visit and shop for their kitchen
            essentials.
          </p>
        </div>
      </div>

      <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex flex-col md:flex-row gap-8 justify-center items-stretch mt-16">
        <div className="w-full md:hidden">
          <img
            className="rounded-md h-52 object-cover"
            src={sny2}
            alt="Service"
          />
        </div>
        <div className="w-full md:w-[50%] bg-gray-100 text-gray-800 p-4 rounded-md">
          <h2 className="text-2xl font-semibold font-serif">
            Our Vision and Goals
          </h2>
          <p className="text-[17px] mt-4">
            At SNY, our vision is to be the leading provider of high-quality
            kitchen utensils in Nepal, known for our exceptional products and
            customer service. Our goals include expanding our innovative product
            range, ensuring reliability and affordability, and fostering strong
            customer relationships. We are also committed to sustainability,
            aiming to introduce eco-friendly options to support a greener
            environment. Through these efforts, we strive to enhance the cooking
            experience and positively impact the culinary community in Kathmandu
            and beyond.
          </p>
        </div>
        <div className="w-[50%] hidden md:block">
          <img
            className="rounded-md h-80 object-cover"
            src={sny2}
            alt="Service"
          />
        </div>
      </div>
      <Faq />
    </section>
  );
}
