import React, { useEffect, useRef, useState } from "react";
import top1 from "../assets/top1.jpg";
import top2 from "../assets/el.jpg";
import top3 from "../assets/top3.jpg";
import top6 from "../assets/dis.jpg";
import top7 from "../assets/top7.jpg";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome CSS

const slides = [
  {
    id: 2,
    title: "Chafing Dish Hydraulic  ",
    description: "SNY INTERNATIONAL",
    img: top2,
    url: "/",
    bg: "bg-gradient-to-r from-pink-50 to-blue-50",
  },
  {
    id: 4,
    title: "Chafing Dish Golden ",
    description: "SNY INTERNATIONAL",
    img: top6,
    url: "/",
    bg: "bg-gradient-to-r from-red-50 to-green-50",
  },
  {
    id: 1,
    title: "Color Milk Pitcher",
    description: "SNY INTERNATIONAL",
    img: top1,
    url: "/",
    bg: "bg-gradient-to-r from-yellow-50 to-pink-50",
  },

  {
    id: 3,
    title: "Color Tea Cup",
    description: "SNY INTERNATIONAL",
    img: top3,
    url: "/",
    bg: "bg-gradient-to-r from-blue-50 to-yellow-50",
  },

  {
    id: 5,
    title: "Color Bowl ",
    description: "SNY INTERNATIONAL",
    img: top7,
    url: "/",
    bg: "bg-gradient-to-l from-spring-100 to-blue-50",
  },
];

const Slider = () => {
  const [current, setCurrent] = useState(0);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [orderName, setOrderName] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const formRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleOrderNowClick = () => {
    setShowOrderForm(true);
  };

  const handleCloseClick = () => {
    setShowOrderForm(false);
  };

  const handleContact = (name) => {
    console.log(`Thank You, ${name}! Your Message Has Been Sent`);
    // You can add additional logic here after successfully sending the message
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Compose email body
      const mailBody = `Name: ${name}\nEmail: ${email}\nAddress: ${address}\nOrder Name: ${orderName} \nOrder Date: ${orderDate}`;

      // Open mail client with pre-filled details
      window.open(
        `mailto:snyinternational12@gmail.com?subject=Message from ${name}&body=${encodeURIComponent(
          mailBody
        )}`
      );

      // Reset form fields
      formRef.current.reset();
      setName("");
      setEmail("");
      setAddress("");
      setOrderName("");
      setOrderDate("");

      // Optionally, you can show a success message or trigger further actions
      console.log("Message Sent");
      handleContact(name);

      // Close the order form
      setShowOrderForm(false);
    } catch (error) {
      console.error("Failed to send message. Please try again later.", error);
      // Handle error state or show an error message to the user
    }
  };

  return (
    <div className="-mt-12 h-[calc(100vh-80px)] overflow-hidden relative">
      <div
        className="w-max h-full flex transition-all ease-in-out duration-1000"
        style={{ transform: `translateX(-${current * 100}vw)` }}
      >
        {slides.map((slide) => (
          <div
            className={`${slide.bg} w-screen h-full flex flex-col gap-16 xl:flex-row`}
            key={slide.id}
          >
            {/* TEXT CONTAINER */}
            <div className="h-1/2 xl:w-1/2 xl:h-full flex flex-col items-center justify-center gap-8 2xl:gap-12 text-center">
              <h2 className="text-xl curve-underline lg:text-3xl 2xl:text-5xl font-serif">
                {slide.description}
              </h2>
              <h1 className="text-4xl lg:text-5xl 2xl:text-7xl font-serif">
                {slide.title}
              </h1>
              <button
                className="rounded-md bg-primary-350 hover:bg-primary-300 duration-300 text-sm text-white py-2 px-7"
                onClick={handleOrderNowClick}
              >
                Order Now
              </button>
            </div>
            {/* IMAGE CONTAINER */}
            <div className="h-1/2 xl:w-1/2 xl:h-full relative">
              <img
                src={slide.img}
                alt={slide.title}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        ))}
      </div>
      {/* Pagination dots */}
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-8 flex gap-4">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`w-3 h-3 rounded-full ring-1 ring-gray-600 cursor-pointer flex items-center justify-center ${
              current === index ? "scale-150" : ""
            }`}
            onClick={() => setCurrent(index)}
          >
            {current === index && (
              <div className="w-1.5 h-1.5 bg-gray-600 rounded-full"></div>
            )}
          </div>
        ))}
      </div>

      {/* Order Form */}
      {showOrderForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/80 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full relative">
            <button
              className="absolute top-4 right-4 text-gray-900 md:text-4xl text-3xl hover:text-gray-700"
              onClick={handleCloseClick}
            >
              &times;
            </button>
            <h2 className="text-xl font-semibold font-serif mb-4">
              Place Your Order
            </h2>
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full border outline-primary-150 border-gray-300 p-2 rounded"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full border outline-primary-150 border-gray-300 p-2 rounded"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="address" className="block text-sm mb-1">
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  className="w-full border outline-primary-150 border-gray-300 p-2 rounded"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="orderName" className="block text-sm mb-1">
                  Order Name
                </label>
                <input
                  type="text"
                  id="orderName"
                  className="w-full border outline-primary-150 border-gray-300 p-2 rounded"
                  value={orderName}
                  onChange={(e) => setOrderName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="orderDate" className="block text-sm mb-1">
                  Order Date
                </label>
                <input
                  type="date"
                  id="orderDate"
                  className="w-full border outline-primary-150 border-gray-300 p-2 rounded"
                  value={orderDate}
                  onChange={(e) => setOrderDate(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-primary-350 text-white hover:bg-primary-300 duration-500 py-1 px-7 rounded"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;
